import React from "react";
import IntegrationTemplate from "../../templates/integration";
import * as Ui from "components/ui";
import * as Sections from "components/sections";
import * as Layout from "components/layout";
import IntegrationStorageContent from "components/content/integration-storage";
import { meta } from "../../configs/storage-providers";
const ContentPage = () => (
  <IntegrationTemplate
    key="synology"
    name="Synology C2"
    shortDescription="Store your backups on Synology C2."
    website={"https://c2.synology.com/en-global"}
    links={[{ text: "Documentation", url: "/docs" }]}
    category="Storage"
    logo="synology-ico.png"
    metaTitle={meta.title.replace("##provider##", "Synology C2 Storage")}
    metaDescription={meta.description.replace(
      "##provider##",
      "Synology C2 Storage"
    )}
  >
    <IntegrationStorageContent
      keyRef="synology"
      service="Synology C2 bucket"
      provider="Synology"
      learnTags={["synology"]}
      contentBlocks={[
        {
          title: "Synology C2 bucket",
          text:
            "We offer extensive support for Synology C2.\n" +
            "The new Synology S3-compliant mode, and the support for master keys & application keys.\n" +
            "Supports buckets, and all Synology C2 regions!",
        },
      ]}
    />
  </IntegrationTemplate>
);

export default ContentPage;
